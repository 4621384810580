
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import Step1 from './components/step1/index.vue';
import Step2 from './components/step2/index.vue';
import Step3 from './components/step3/index.vue';

export default defineComponent({
  name: 'StepForm',
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const current = ref(0);

    const nextStep = () => {
      if (current.value < 2) {
        current.value += 1;
      }
    };
    const prevStep = () => {
      if (current.value > 0) {
        current.value -= 1;
      }
    };
    const finish = () => {
      current.value = 0;
    };

    return {
      t,
      route,

      current,
      nextStep,
      prevStep,
      finish,
    };
  },
  components: {
    Step1,
    Step2,
    Step3,
  },
});
